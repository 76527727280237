import { makeAutoObservable } from 'mobx'

import RootStore from './index'

export default class GlobalStore {
  public rootStore: RootStore

  public isDarkTheme = false

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
  }

  toggleTheme() {
    this.isDarkTheme = !this.isDarkTheme
  }
}
