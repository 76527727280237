import { ApolloClient, InMemoryCache } from '@apollo/client'

import * as GraphClient from '~/.graphclient'
import { MeshApolloLink } from './graphQL/queries'

const apolloClient = new ApolloClient({
  link: new MeshApolloLink(GraphClient),
  cache: new InMemoryCache()
})

export default apolloClient
