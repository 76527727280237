import { makeAutoObservable } from 'mobx'

import RootStore from './index'

export default class YieldsStore {
  public rootStore: RootStore

  public yieldsData: any[] = []

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
  }

  setYieldsData(data?: any[]) {
    this.yieldsData = data
  }
}
