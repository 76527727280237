import { AssetsPriceList } from '@/config/constants/assets'

const fetchLendTestPrice = async (url) => {
  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `query {
          token(id: "0x25356aeca4210ef7553140edb9b8026089e49396"){
            name
            derivedUSD
            derivedNATIVE
          }
        }`
      })
    })

    if (!response.ok) {
      throw new Error(`ERROR HTTP fetch Lend price: ${response.status}`)
    }

    const data = await response.json()
    return data
  } catch (error) {
    console.error('ERROR fetch Lend price:', error)
  }
}

export const getLendPriceFX = async () => {
  const fusionxgGraphV3Url = 'https://graphv3.fusionx.finance/subgraphs/name/fusionx/exchange'
  const fusionxgGraphUrl = 'https://graph.fusionx.finance/subgraphs/name/fusionx/exchange'

  try {
    const lendTestPriceDataV3 = await fetchLendTestPrice(fusionxgGraphV3Url)
    if (lendTestPriceDataV3?.data?.token?.derivedUSD) {
      return lendTestPriceDataV3.data.token.derivedUSD
    }

    const lendTestPriceData = await fetchLendTestPrice(fusionxgGraphUrl)
    if (lendTestPriceData?.data?.token?.derivedUSD) {
      return lendTestPriceData.data.token.derivedUSD
    }

    console.error('ERROR: Lend price not received')
    return 0
  } catch (error) {
    console.error('ERROR fetch Lend price:', error)
    return 0
  }
}

export const getAssetsPrices = async (ids: any) => {
  const prices = {}
  const chunk = (arr: any[], n: any) => (arr.length ? [arr.slice(0, n), ...chunk(arr.slice(n), n)] : [])

  for (const idc of chunk(ids, 50)) {
    const ids = idc.join('%2C')
    const response = await fetch(`https://api.coingecko.com/api/v3/simple/price?ids=${ids}&vs_currencies=usd`)
    const res = await response.json()
    for (const [key, value] of Object.entries(res)) {
      const assetKey = Object.keys(AssetsPriceList).find((listKey) => AssetsPriceList[listKey] === key)
      if ((value as any).usd && assetKey) prices[assetKey] = value
    }
  }

  return prices
}
