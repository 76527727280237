import { autorun, makeAutoObservable, runInAction } from 'mobx'
import { ApolloClient } from '@apollo/client'

import client from '@/utils/apollo'

import {
  MarketsDataDocument,
  MetaDataDocument,
  MetaDataSDocument,
  MetaDataQuery,
  MarketsDataQuery,
  AccountPositionsDocument,
  AccountPositionsQuery
} from '~/.graphclient'

import RootStore from '@/stores'
import { AccountPosition, MetaData, Price } from '@/stores/types'

export default class GraphStore {
  public rootStore: RootStore

  public client: ApolloClient<any>

  public metaData: MetaData = {} as MetaData

  public marketData: MarketsDataQuery

  public prices: Record<Lowercase<string>, Price> = {}

  public accountPositionsData: Record<Lowercase<string>, AccountPosition> = {}

  private accountPositionSubscribe?: {
    accountAddress: string
    subscribe: any
  }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true })

    this.rootStore = rootStore

    this.client = client

    this.init()
  }

  init() {
    this.watchMetaData()
    // this.watchMarketData()
    // this.subscribeMetaData()

    // autorun(() => {
    //   this.watchAccountPosition()
    // })
  }

  async watchMetaData() {
    this.client
      .watchQuery({
        query: MetaDataDocument,
        pollInterval: 5000,
        fetchPolicy: 'cache-and-network'
      })
      .subscribe({
        next: ({ data }) => this.updateMetaData(data),
        error: (e) => console.error(e)
      })
  }

  updateMetaData(data: MetaDataQuery) {
    runInAction(() => {
      this.metaData = {
        block: data._meta.block.number,
        timestamp: data._meta.block.timestamp
      }
    })
  }

  async watchAccountPosition() {
    const accountAddress = this.rootStore.walletAccountStore.activeUserAccountAddress

    if (!accountAddress) {
      if (this.accountPositionSubscribe?.subscribe) {
        this.accountPositionSubscribe.subscribe?.unsubscribe()
        this.accountPositionSubscribe = null
      }

      return
    }

    if (accountAddress && this.accountPositionSubscribe) {
      if (
        this.accountPositionSubscribe?.accountAddress &&
        this.accountPositionSubscribe?.subscribe &&
        this.accountPositionSubscribe.accountAddress !== accountAddress
      ) {
        this.accountPositionSubscribe?.subscribe?.unsubscribe()
        this.accountPositionSubscribe = null
      } else {
        return
      }
    }

    const accountPositionSubscribe = this.client
      .watchQuery({
        query: AccountPositionsDocument,
        pollInterval: 10000,
        fetchPolicy: 'cache-and-network',
        variables: {
          id: accountAddress
        }
      })
      .subscribe({
        next: ({ data }) => {
          this.updateAccountPositionData(data)
        },
        error: (e) => console.error(e)
      })

    this.accountPositionSubscribe = { accountAddress, subscribe: accountPositionSubscribe }
  }

  updateAccountPositionData(data: AccountPositionsQuery) {
    const accountPositions: Record<Lowercase<string>, AccountPosition> = {}

    data?.account?.positions.forEach((item) => {
      if (!accountPositions[item.market.id]) {
        accountPositions[item.market.id] = {
          assetId: item.market.id,
          assetName: item.market.name,
          symbol: item.market.inputToken.symbol,
          decimals: item.market.inputToken.decimals,
          tokenPrice: item.market.inputTokenPriceUSD
        }
      }

      if (item.side === 'LENDER') {
        accountPositions[item.market.id].supplyAmount = item.balance
      }

      if (item.side === 'BORROWER') {
        accountPositions[item.market.id].borrowAmount = item.balance
      }

      if (item.side === 'LENDER' && item.isCollateral) {
        accountPositions[item.market.id].isCollateral = item.isCollateral
      }
    })

    runInAction(() => {
      this.accountPositionsData = accountPositions
    })
  }

  async watchMarketData() {
    this.client
      .watchQuery({
        query: MarketsDataDocument,
        pollInterval: 10000,
        fetchPolicy: 'cache-and-network'
      })
      .subscribe({
        next: ({ data }) => {
          // console.log('watchMarketData', data)
          this.updateMarketData(data)
        },
        error: (e) => console.error(e)
      })
  }

  async subscribeMetaData() {
    this.client
      .subscribe({
        query: MetaDataSDocument
      })
      .subscribe({
        next: ({ data }) => {
          console.log('subscribeMetaData', data)
        },
        error: (e) => console.error(e)
      })
  }

  updateMarketData(data: MarketsDataQuery) {
    const prices = data?.markets?.reduce((acc, market) => {
      const reserveAddress = market.id.toLowerCase()
      const price = market.inputTokenPriceUSD

      const newAcc = { ...acc, [reserveAddress]: { usd: price } }

      return newAcc
    }, {})

    runInAction(() => {
      this.marketData = data
      this.prices = prices
      // this.rootStore.walletAccountStore.updatePrices(prices)
    })
  }

  async getMarketData() {
    try {
      const result = await this.client.query({
        query: MarketsDataDocument,
        pollInterval: 5000
      })

      runInAction(() => {
        this.marketData = result.data
      })
    } catch (error) {
      console.log(error)
    }
  }
}
