import { ethers } from 'ethers'
import configJson from '@/config/index.json'
import lendingPoolJson from '@/config/abi/LendingPool.json'
import lendingPoolAddressesProviderJson from '@/config/abi/LendingPoolAddressesProvider.json'
import aaveProtocolDataProviderJson from '@/config/abi/AaveProtocolDataProvider.json'
import ChefIncentivesControllerJson from '@/config/abi/ChefIncentivesController.json'
import projectTokenJson from '@/config/abi/ProjectToken.json'
import UiPoolDataProviderJson from '@/config/abi/UiPoolDataProvider.json'
import MultiFeeDistributionJson from '@/config/abi/MultiFeeDistribution.json'
import MerkleDistributionJson from '@/config/abi/MerkleDistributor.json'
import DefaultReserveInterestRateStrategyJson from '@/config/abi/DefaultReserveInterestRateStrategy.json'

type lpConfig = {
  lpContract: ethers.Contract
  dataContract: ethers.Contract
  chefIncentivesControllerContract: ethers.Contract
  projectTokenContract: ethers.Contract
  uiPoolDataProviderContract: ethers.Contract
  multiFeeDistributionContract: ethers.Contract
  merkleDistributionContract: ethers.Contract
  defaultReserveInterestRateStrategyContract: ethers.Contract
  lpAddress: string
  LendingPoolAddressesProvider: string
  MultiFeeDistribution: string
  MerkleDistributor: string
  network: ethers.Network
}

const networkConfig = (chainId: number): Record<string, string> => configJson.networks[chainId]

export class LpUtils {
  private provider: ethers.BrowserProvider | ethers.JsonRpcProvider | ethers.WebSocketProvider

  private lpConfig: lpConfig = {} as lpConfig

  constructor(provider: ethers.BrowserProvider | ethers.JsonRpcProvider | ethers.WebSocketProvider) {
    this.provider = provider
  }

  async setLpConfig() {
    this.lpConfig = await this.createLpConfig()
  }

  async getLpConfig() {
    return this.lpConfig
  }

  async createLpConfig() {
    if (this.provider == null) return

    const network = await this.provider.getNetwork()

    const config = networkConfig(Number(network.chainId))

    if (!config) {
      // throw new Error(`Network config not found. [ChainId: ${network.chainId}]`)
    }

    const {
      AaveProtocolDataProvider,
      LendingPoolAddressesProvider,
      ChefIncentivesController,
      ProjectToken,
      UiPoolDataProvider,
      MultiFeeDistribution,
      MerkleDistributor,
      DefaultReserveInterestRateStrategy
    } = config

    const addressProviderContract = new ethers.Contract(
      LendingPoolAddressesProvider,
      lendingPoolAddressesProviderJson.abi,
      this.provider
    )
    if (!addressProviderContract) {
      return
    }
    const lpAddress = await addressProviderContract.getLendingPool()

    return {
      lpContract: new ethers.Contract(lpAddress, lendingPoolJson.abi, this.provider),
      dataContract: new ethers.Contract(AaveProtocolDataProvider, aaveProtocolDataProviderJson.abi, this.provider),
      chefIncentivesControllerContract: new ethers.Contract(
        ChefIncentivesController,
        ChefIncentivesControllerJson.abi,
        this.provider
      ),
      projectTokenContract: ProjectToken
        ? new ethers.Contract(ProjectToken, projectTokenJson.abi, this.provider)
        : null,
      uiPoolDataProviderContract: new ethers.Contract(UiPoolDataProvider, UiPoolDataProviderJson.abi, this.provider),
      multiFeeDistributionContract: new ethers.Contract(
        MultiFeeDistribution,
        MultiFeeDistributionJson.abi,
        this.provider
      ),
      defaultReserveInterestRateStrategyContract: new ethers.Contract(
        DefaultReserveInterestRateStrategy,
        DefaultReserveInterestRateStrategyJson.abi,
        this.provider
      ),
      merkleDistributionContract: new ethers.Contract(MerkleDistributor, MerkleDistributionJson.abi, this.provider),
      lpAddress,
      LendingPoolAddressesProvider,
      MultiFeeDistribution,
      MerkleDistributor,
      network
    }
  }

  async waitForTransaction(txhash: string, confirmations?: number) {
    const tx = await this.provider.getTransaction(txhash)
    const receipt = await tx.wait(confirmations)

    return receipt
  }
}
