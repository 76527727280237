import { configureChains, Chain, createConfig } from 'wagmi'
import { publicProvider } from 'wagmi/providers/public'
import { connectorsForWallets } from '@rainbow-me/rainbowkit'
import {
  rainbowWallet,
  metaMaskWallet,
  coinbaseWallet,
  walletConnectWallet,
  trustWallet,
  rabbyWallet,
  zerionWallet,
  xdefiWallet,
  tahoWallet,
  safeWallet,
  phantomWallet,
  omniWallet,
  okxWallet,
  mewWallet,
  injectedWallet,
  imTokenWallet,
  dawnWallet,
  braveWallet,
  bitskiWallet,
  argentWallet
} from '@rainbow-me/rainbowkit/wallets'

const Chains: { [chainName: string]: Chain } = {
  core_blockchain_testnet: {
    id: 1115,
    name: 'Core Blockchain Testnet',
    network: 'core_blockchain_testnet',
    nativeCurrency: { name: 'Core Blockchain Testnet', symbol: 'tCORE', decimals: 18 },
    rpcUrls: {
      default: {
        http: ['https://rpc.test.btcs.network']
      },
      public: {
        http: ['https://rpc.test.btcs.network']
      }
    },
    blockExplorers: {
      default: {
        name: 'Core Blockchain Testnet explorer',
        url: 'https://scan.test.btcs.network'
      }
    },
    testnet: true
  },
  core_blockchain_mainnet: {
    id: 1116,
    name: 'Core Blockchain',
    network: 'core_blockchain_mainnet',
    nativeCurrency: { name: 'Core Blockchain', symbol: 'CORE', decimals: 18 },
    rpcUrls: {
      default: {
        http: ['https://rpc.coredao.org']
      },
      public: {
        http: ['https://rpc.coredao.org']
      }
    },
    blockExplorers: {
      default: {
        name: 'Core Blockchain explorer',
        url: 'https://scan.coredao.org'
      }
    },
    testnet: false
  }
}

const activeChains =
  process.env.APP_ENV === 'production'
    ? [Chains.core_blockchain_mainnet]
    : [Chains.core_blockchain_testnet, Chains.core_blockchain_mainnet]
export const defaultChain =
  process.env.APP_ENV === 'production' ? Chains.core_blockchain_mainnet : Chains.core_blockchain_testnet

const { chains, publicClient } = configureChains(activeChains, [publicProvider()])
const projectId = '14b531d474e6ed383d9768ed2f41f16a'
const appName = 'Lendle App'

const connectors = connectorsForWallets([
  {
    groupName: 'Recommended',
    wallets: [
      metaMaskWallet({ projectId, chains }),
      coinbaseWallet({ chains, appName }),
      walletConnectWallet({ projectId, chains }),
      trustWallet({ projectId, chains }),
      rabbyWallet({ chains })
    ]
  },
  {
    groupName: 'Others',
    wallets: [
      rainbowWallet({ projectId, chains }),
      zerionWallet({ projectId, chains }),
      omniWallet({ projectId, chains }),
      okxWallet({ projectId, chains }),
      argentWallet({ projectId, chains }),
      bitskiWallet({ chains }),
      braveWallet({ chains }),
      dawnWallet({ chains }),
      imTokenWallet({ projectId, chains }),
      injectedWallet({ chains }),
      mewWallet({ chains }),
      phantomWallet({ chains }),
      safeWallet({ chains }),
      tahoWallet({ chains }),
      xdefiWallet({ chains })
    ]
  }
])

const wagmiConfig = createConfig({
  autoConnect: true,
  connectors,
  publicClient
})

const wagmiExportConfig = {
  wagmiConfig,
  chains
}

export default wagmiExportConfig
