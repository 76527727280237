export const titleEnum: { [key: string]: number } = Object.freeze({
  balance: 1,
  penalty: 2,
  threshold: 3,
  ratio: 4,
  health: 5,
  collateral: 6
})

interface IData {
  key: number
  title: string
  message: string
}

export const data: IData[] = [
  {
    key: titleEnum.balance,
    title: 'Approximate balance',
    message:
      'Your aggregated balance shows the approximate value in USD of all the assets you have deposited. It fluctuates based on the evolution of prices. Please note that if your deposits consist of stable-coins the approximate balance in USD could be slightly higher or lower than the stable-coin balance, due to fluctuations in the stable-coin peg.'
  },
  {
    key: titleEnum.penalty,
    title: 'Liquidation penalty',
    message:
      'When a liquidation occurs, liquidators repay part or all of the outstanding borrowed amount on behalf of the borrower. In return, they can buy the collateral at a discount and keep the difference as a bonus!'
  },
  {
    key: titleEnum.threshold,
    title: 'Liquidation threshold',
    message:
      'This represents the threshold at which a borrow position will be considered undercollateralized and subject to liquidation for each collateral. For example, if a collateral has a liquidation threshold of 80%, it means that the loan will be liquidated when the debt value is worth 80% of the collateral value.'
  },
  {
    key: titleEnum.ratio,
    title: 'Loan to Value (LTV) Ratio',
    message:
      'The Maximum Loan-to-Value ratio represents the maximum borrowing power of a specific collateral. For example, if a collateral has a LTV of 75%, the user can borrow up to 0.75 worth of USD in the principal currency for every 1 USD worth of collateral.'
  },
  {
    key: titleEnum.health,
    title: 'Health factor',
    message:
      'The health factor represents the safety of your loan derived from the proportion of collateral versus amount borrowed.Keep it above 1 to avoid liquidation.'
  },
  {
    key: titleEnum.collateral,
    title: 'Adding and removing assets as collateral',
    message:
      'Allows you to decide whether to use a deposited asset as collateral. An asset used as collateral will affect your borrowing power and health factor.'
  }
]
