import { makeAutoObservable } from 'mobx'

import { titleEnum } from '@/components/Modal/Modals/data'

import RootStore from './index'

export default class ModalStore {
  public rootStore: RootStore

  public isShowModal = false

  public titleEnum: any

  public titleModal: number

  public isShowStepModal = false

  public isShowTermsModal = false

  public isShowFinOperationModal = false

  public isShowSquidStakeModal = false

  public isShowProfileModal = false

  public isShowNativeTokenWrap = false

  public isShowBuyLend = false

  public termsAction: any

  public finOperationsModalData: {
    asset: string
    contract: string
    method: string
  }

  public stepModalDate: { toggle: boolean; asset: string; assetAddress: string; setToggle: any }

  constructor(rootStore: RootStore) {
    makeAutoObservable(this, {}, { autoBind: true })
    this.rootStore = rootStore
    this.titleEnum = titleEnum
  }

  openInfoModal(title?: any) {
    this.titleModal = title
    this.isShowModal = true
  }

  openStepModal(payload) {
    this.stepModalDate = payload
    this.isShowStepModal = true
  }

  openTermsModal(payload) {
    this.termsAction = payload
    this.isShowTermsModal = true
  }

  openFinOperationsModal(payload: { asset: string; contract: string; method: string }) {
    this.finOperationsModalData = payload
    this.isShowFinOperationModal = true
  }

  openSquidStakeModal() {
    this.isShowSquidStakeModal = true
  }

  openProfileModal() {
    this.isShowProfileModal = true
  }

  openMNTWrapModal() {
    this.isShowNativeTokenWrap = true
  }

  openBuyLendModal() {
    this.isShowBuyLend = true
  }

  closeModal() {
    this.titleModal = undefined
    this.isShowModal = false
    this.isShowStepModal = false
    this.isShowTermsModal = false
    this.isShowFinOperationModal = false
    this.isShowSquidStakeModal = false
    this.isShowProfileModal = false
    this.isShowNativeTokenWrap = false
    this.isShowBuyLend = false
  }
}
