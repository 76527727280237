import { ethers } from 'ethers'
import { formatBIUnit, formatSymbol } from '@/utils/format'
import aTokenJson from '@/config/abi/AToken.json'
import bTokenJson from '@/config/abi/VariableDebtToken.json'

export const loadReserve = async (lpContract, dataProviderContract, address) => {
  const lendingData = await lpContract.getReserveData(address)
  const { aTokenAddress, variableDebtTokenAddress } = lendingData

  const ATOKEN_CONTRACT = new ethers.Contract(aTokenAddress, aTokenJson.abi, lpContract.provider)
  const BTOKEN_CONTRACT = new ethers.Contract(variableDebtTokenAddress, bTokenJson.abi, lpContract.provider)

  const aDecimals = await ATOKEN_CONTRACT.decimals()
  const aTotalSupply_ = await ATOKEN_CONTRACT.totalSupply()
  const aPrice_ = await ATOKEN_CONTRACT.getAssetPrice()
  const aPrice = formatBIUnit(aPrice_, 18)
  // const aTotalSupply = aTotalSupply_ / 10 ** aDecimals

  const bDecimals = await BTOKEN_CONTRACT.decimals()
  const bTotalSupply_ = await BTOKEN_CONTRACT.totalSupply()
  // const bTotalSupply = bTotalSupply_ / 10 ** bDecimals

  const reserveContract = new ethers.Contract(address, aTokenJson.abi, lpContract.provider)
  const uDecimals = await reserveContract.decimals()
  const uReserves_ = await reserveContract.balanceOf(aTokenAddress)
  // const uReserves = uReserves_ / 10 ** uDecimals

  // const underlyingPrice = getParameterCaseInsensitive(prices, reserveAddress)?.usd
  // const rewardTokenPrice = getParameterCaseInsensitive(prices, "0xd8321aa83fb0a4ecd6348d4577431310a6e0814d")?.usd
  const supplyRate = (BigInt(lendingData.currentLiquidityRate) / BigInt(1e27)) * BigInt(100)
  const borrowRate = (BigInt(lendingData.currentVariableBorrowRate) / BigInt(1e27)) * BigInt(100)

  const loadReserveConfigurationData = async (dataProviderContract, address) => {
    const data = await dataProviderContract.getReserveConfigurationData(address)
    const {
      decimals,
      ltv,
      liquidationThreshold,
      liquidationBonus,
      reserveFactor,
      usageAsCollateralEnabled,
      borrowingEnabled,
      stableBorrowRateEnabled
      // isActive
      // isFrozen
    } = data

    return {
      decimals: Number(decimals),
      ltv,
      liquidationThreshold,
      liquidationBonus,
      reserveFactor,
      usageAsCollateralEnabled,
      borrowingEnabled,
      stableBorrowRateEnabled
    }
  }

  const configuration = await loadReserveConfigurationData(dataProviderContract, address)
  const name = await reserveContract.name()
  const symbol = await reserveContract.symbol()

  return {
    address: address.toLowerCase(),
    name,
    symbol: formatSymbol(symbol),
    decimals: Number(uDecimals),
    amount: uReserves_,
    available: BigInt(uReserves_) - BigInt(bTotalSupply_), // ! Is it correct?
    supplied: {
      token: aTokenAddress.toLowerCase(),
      amount: aTotalSupply_,
      decimals: Number(aDecimals),
      aprPct: Number(supplyRate).toFixed(2)
    },
    borrowed: {
      token: variableDebtTokenAddress.toLowerCase(),
      amount: bTotalSupply_,
      decimals: Number(bDecimals),
      aprPct: Number(borrowRate).toFixed(2)
    },
    configuration,
    price: aPrice
  }
}

export const loadAccountReserveBalance = async (lpContract, address, account) => {
  const runner = await lpContract.runner
  const reserveContract = new ethers.Contract(address, aTokenJson.abi, runner)
  const balance = await reserveContract.balanceOf(account)

  return { address, balance }
}

export const loadAccountReserveData = async (lpContract, address, account) => {
  const lendingData = await lpContract.getReserveData(address)
  const { aTokenAddress, variableDebtTokenAddress } = lendingData

  const runner = await lpContract.runner

  const ATOKEN_CONTRACT = new ethers.Contract(aTokenAddress, aTokenJson.abi, runner)
  const BTOKEN_CONTRACT = new ethers.Contract(variableDebtTokenAddress, bTokenJson.abi, runner)
  const reserveContract = new ethers.Contract(address, aTokenJson.abi, runner)

  const aDecimals = await ATOKEN_CONTRACT.decimals()
  const aTotalSupply_ = await ATOKEN_CONTRACT.totalSupply()
  const aBalanceOf_ = await ATOKEN_CONTRACT.balanceOf(account)
  const aPrice_ = await ATOKEN_CONTRACT.getAssetPrice()
  const aPrice = formatBIUnit(aPrice_, 18)

  const uDecimals = await reserveContract.decimals()
  const uSymbol = await reserveContract.symbol()

  const aPct = Number(aTotalSupply_) === 0 ? BigInt(0) : (BigInt(aBalanceOf_) / BigInt(aTotalSupply_)) * BigInt(100)

  const bDecimals = await BTOKEN_CONTRACT.decimals()
  const bTotalSupply_ = await BTOKEN_CONTRACT.totalSupply()
  const bBalanceOf_ = await BTOKEN_CONTRACT.balanceOf(account)
  const bPct = Number(bTotalSupply_) === 0 ? BigInt(0) : (BigInt(bBalanceOf_) / BigInt(bTotalSupply_)) * BigInt(100)

  // const uDecimals = await reserveContract.decimals()
  // const uSymbol = await reserveContract.symbol()

  const supplyRate = lendingData.currentLiquidityRate
  const borrowRate = lendingData.currentVariableBorrowRate

  return {
    address: address.toLowerCase(),
    account: account.toLowerCase(),
    balance: await reserveContract.balanceOf(account),
    reserveBalance: await reserveContract.balanceOf(aTokenAddress),
    decimals: Number(uDecimals),
    priceETH: aPrice_,
    price: aPrice,
    supplying: {
      token: aTokenAddress.toLowerCase(),
      amount: aBalanceOf_,
      decimals: Number(aDecimals),
      symbol: formatSymbol(uSymbol),
      pct: aPct,
      apy: supplyRate
    },
    borrowing: {
      token: variableDebtTokenAddress.toLowerCase(),
      amount: bBalanceOf_,
      decimals: Number(bDecimals),
      symbol: formatSymbol(uSymbol),
      pct: bPct,
      apy: borrowRate
    }
  }
}

export const loadUserAccountData = async (lpContract, account) => {
  const userAccountData = await lpContract.getUserAccountData(account)

  return {
    account: account.toLowerCase(),
    totalCollateralETH: userAccountData.totalCollateralETH,
    totalDebtETH: userAccountData.totalDebtETH,
    availableBorrowsETH: userAccountData.availableBorrowsETH,
    currentLiquidationThreshold: userAccountData.currentLiquidationThreshold,
    ltv: userAccountData.ltv,
    healthFactor: userAccountData.healthFactor
  }
}

export const loadProjectTokenData = async (lpContract, projectTokenContract, account) => {
  if (projectTokenContract) {
    const address = (await projectTokenContract.getAddress()).toLowerCase()
    const tDecimals_ = await projectTokenContract.decimals()
    const tTotalSupply_ = await projectTokenContract.totalSupply()
    const tSymbol_ = await projectTokenContract.symbol()
    const tBalanceOf_ = await projectTokenContract.balanceOf(account)
    const tPct_ = Number(tBalanceOf_ / tTotalSupply_) * 100
    const decimals = Number(tDecimals_)

    return {
      account: account.toLowerCase(),
      address: address.toLowerCase(),
      total: tTotalSupply_,
      amount: tBalanceOf_,
      decimals,
      symbol: formatSymbol(tSymbol_),
      pct: tPct_
    }
  }
}

export const loadReserveIncentives = async (
  chefIncentivesControllerContract: any,
  reserve: string,
  aTokenAddress: string,
  variableDebtTokenAddress: string
) => {
  const aTokenPoolInfo = await chefIncentivesControllerContract.poolInfo(aTokenAddress)
  const supplyAllocPoint = Number(aTokenPoolInfo.allocPoint)
  const variableDebtTokenPoolInfo = await chefIncentivesControllerContract.poolInfo(variableDebtTokenAddress)
  const borrowAllocPoint = Number(variableDebtTokenPoolInfo.allocPoint)

  return {
    address: reserve.toLowerCase(),
    supplyAllocPoint,
    borrowAllocPoint
  }
}

export const loadAccountIncentives = async (
  chefIncentivesControllerContract: any,
  reserve: string,
  aTokenAddress: string,
  variableDebtTokenAddress: string,
  account: string
) => {
  const dataClaimableReward = await chefIncentivesControllerContract.claimableReward(account, [
    aTokenAddress,
    variableDebtTokenAddress
  ])

  const supplyReward = dataClaimableReward?.[0] ?? 0
  const borrowReward = dataClaimableReward?.[1] ?? 0

  return {
    address: reserve.toLowerCase(),
    account: account?.toLowerCase(),
    supplyReward,
    borrowReward
  }
}

export const loadAccountBaseClaimable = async (chefIncentivesControllerContract: any, account: string) => {
  const dataUserBaseClaimable = await chefIncentivesControllerContract.userBaseClaimable(account)

  const userBaseClaimableReward = dataUserBaseClaimable ?? 0

  return { userBaseClaimableReward }
}

export const loadMultiFeeDistribution = async (multiFeeDistributionContract: any, account: string) => {
  const resEarned = await multiFeeDistributionContract.earnedBalances(account)
  const totalEarned = resEarned.total
  const { earningsData } = resEarned
  const resLocked = await multiFeeDistributionContract.lockedBalances(account)
  const { lockData } = resLocked
  const { locked } = resLocked
  const totalLocked = resLocked.total
  const { unlockable } = resLocked
  const resUnlocked = await multiFeeDistributionContract.unlockedBalance(account)
  const unlockedBalance = resUnlocked
  const resWithdrawable = await multiFeeDistributionContract.withdrawableBalance(account)
  const withdrawableAmount = resWithdrawable.amount
  const { penaltyAmount } = resWithdrawable
  const resLockedSupply = await multiFeeDistributionContract.lockedSupply()
  const lockedSupply = resLockedSupply
  const resTotalSupply = await multiFeeDistributionContract.totalSupply()
  const totalSupply = resTotalSupply
  const claimableRewards = await multiFeeDistributionContract.claimableRewards(account)
  const totalBalance = await multiFeeDistributionContract.totalBalance(account)
  const withdrawableWithoutPenalty = BigInt(totalBalance - totalLocked - totalEarned)
  const stakedSupply = BigInt(resTotalSupply - resLockedSupply)

  return {
    totalEarned,
    earningsData,
    lockData,
    locked,
    totalLocked,
    unlockable,
    unlockedBalance,
    withdrawableAmount,
    penaltyAmount,
    lockedSupply,
    totalSupply,
    claimableRewards,
    totalBalance,
    withdrawableWithoutPenalty,
    stakedSupply
  }
}

export const loadUiPoolData = async (
  uiPoolDataProviderContract,
  lpAddress,
  account,
  defaultReserveInterestRateStrategyContract
) => {
  const optimalUtilizationRate = await defaultReserveInterestRateStrategyContract.OPTIMAL_UTILIZATION_RATE()
  const resData = await uiPoolDataProviderContract.getReservesData(lpAddress, account)

  const reserveData = {}
  resData[0].forEach((r) => {
    reserveData[r[0].toLowerCase()] = {
      underlyingAsset: r[0].toLowerCase(),
      name: r[1],
      symbol: formatSymbol(r[2]),
      decimals: Number(r[3]),
      baseLTVasCollateral: r[4],
      reserveLiquidationThreshold: r[5],
      reserveLiquidationBonus: r[6],
      reserveFactor: r[7],
      usageAsCollateralEnabled: r[8],
      borrowingEnabled: r[9],
      stableBorrowRateEnabled: r[10],
      isActive: r[11],
      isFrozen: r[12],
      liquidityIndex: r[13],
      variableBorrowIndex: r[14],
      liquidityRate: r[15],
      variableBorrowRate: r[16],
      stableBorrowRate: r[17],
      lastUpdateTimestamp: r[18],
      aTokenAddress: r[19].toLowerCase(),
      stableDebtTokenAddress: r[20].toLowerCase(),
      variableDebtTokenAddress: r[21].toLowerCase(),
      interestRateStrategyAddress: r[22].toLowerCase(),
      availableLiquidity: r[23],
      totalPrincipalStableDebt: r[24],
      averageStableRate: r[25],
      stableDebtLastUpdateTimestamp: r[26],
      totalScaledVariableDebt: r[27],
      priceInEth: r[28],
      variableRateSlope1: r[29],
      variableRateSlope2: r[30],
      stableRateSlope1: r[31],
      stableRateSlope2: r[32],
      aEmissionPerSecond: r[33],
      vEmissionPerSecond: r[34],
      sEmissionPerSecond: r[35],
      aIncentivesLastUpdateTimestamp: r[36],
      vIncentivesLastUpdateTimestamp: r[37],
      sIncentivesLastUpdateTimestamp: r[38],
      aTokenIncentivesIndex: r[39],
      vTokenIncentivesIndex: r[40],
      sTokenIncentivesIndex: r[41],
      optimalUtilizationRate
    }
  })
  const userReserve = {}
  resData[1].forEach((r) => {
    userReserve[r[0].toLowerCase()] = {
      underlyingAsset: r[0].toLowerCase(),
      scaledATokenBalance: r[1],
      usageAsCollateralEnabledOnUser: r[2],
      stableBorrowRate: r[3],
      scaledVariableDebt: r[4],
      principalStableDebt: r[5],
      stableBorrowLastUpdateTimestamp: r[6],
      aTokenincentivesUserIndex: r[7],
      vTokenincentivesUserIndex: r[8],
      sTokenincentivesUserIndexc: r[9]
    }
  })
  const incentiveData = resData[3]
  const incentive = {
    userUnclaimedRewards: incentiveData[0],
    emissionEndTimestamp: incentiveData[1]
    // total: '',
    // earningsData: []
  }
  const uiPoolData = { reserveData, userReserve, incentive }
  return uiPoolData
}

export const loadMarketData = async (lpContract, address) => {
  const lendingData = await lpContract.getReserveData(address)
  const { aTokenAddress, variableDebtTokenAddress } = lendingData

  const ATOKEN_CONTRACT = new ethers.Contract(aTokenAddress, aTokenJson.abi, lpContract.provider)
  const BTOKEN_CONTRACT = new ethers.Contract(variableDebtTokenAddress, bTokenJson.abi, lpContract.provider)

  const [aDecimals, aTotalSupply_, aPrice_, bTotalSupply_] = await Promise.all([
    ATOKEN_CONTRACT.decimals(),
    ATOKEN_CONTRACT.totalSupply(),
    ATOKEN_CONTRACT.getAssetPrice(),
    BTOKEN_CONTRACT.totalSupply()
  ])

  const aPrice = formatBIUnit(aPrice_, 18)

  const reserveContract = new ethers.Contract(address, aTokenJson.abi, lpContract.provider)

  const supplyRate = (BigInt(lendingData.currentLiquidityRate) / BigInt(1e27)) * BigInt(100)
  const borrowRate = (BigInt(lendingData.currentVariableBorrowRate) / BigInt(1e27)) * BigInt(100)

  const symbol = await reserveContract.symbol()

  return {
    address: address.toLowerCase(),
    symbol: formatSymbol(symbol),
    price: aPrice,
    decimals: Number(aDecimals),
    supplied: {
      token: aTokenAddress.toLowerCase(),
      amount: aTotalSupply_,
      decimals: Number(aDecimals),
      aprPct: supplyRate
    },
    borrowed: {
      token: variableDebtTokenAddress.toLowerCase(),
      amount: bTotalSupply_,
      decimals: Number(aDecimals),
      aprPct: borrowRate
    }
  }
}

export const loadMarketConfiguration = async (lpContract, dataProviderContract, address) => {
  const lendingData = await lpContract.getReserveData(address)
  const { aTokenAddress } = lendingData

  const reserveContract = new ethers.Contract(address, aTokenJson.abi, lpContract.provider)
  const uReserves_ = await reserveContract.balanceOf(aTokenAddress)

  const loadReserveConfigurationData = async (dataProviderContract, address) => {
    const data = await dataProviderContract.getReserveConfigurationData(address)

    const {
      decimals,
      ltv,
      liquidationThreshold,
      liquidationBonus,
      reserveFactor,
      usageAsCollateralEnabled,
      borrowingEnabled,
      stableBorrowRateEnabled
    } = data

    return {
      decimals: Number(decimals),
      ltv,
      liquidationThreshold,
      liquidationBonus,
      reserveFactor,
      usageAsCollateralEnabled,
      borrowingEnabled,
      stableBorrowRateEnabled
    }
  }

  const configuration = await loadReserveConfigurationData(dataProviderContract, address)
  const name = await reserveContract.name()

  return {
    address: address.toLowerCase(),
    name,
    amount: uReserves_,
    available: uReserves_,
    configuration
  }
}
