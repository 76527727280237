export const finOperationsEnum: { [key: string]: string } = Object.freeze({
  deposit: 'deposit',
  withdraw: 'withdraw',
  borrow: 'borrow',
  repay: 'repay',
  stake: 'stake',
  lock: 'lock'
})

export const healthFactorWarningLevel = 1.05

export interface IFinOperationsStep {
  title: string
  status: string
  explorerURL: string
  isPending?: boolean
}

interface IFinOperationsData {
  key: string
  title: string
  backLink: string
  contentAmountEntryForm: [string, string, string?]
  approveRequired?: boolean
  modal: {
    title: string
    balance: string
    processing: string
    success: string
    totalSteps: number
  }
}

export const finOperationsData: IFinOperationsData[] = [
  {
    key: finOperationsEnum.deposit,
    title: 'Deposit',
    backLink: '/',
    contentAmountEntryForm: [
      'How much would you like to deposit?',
      'Please enter an amount you would like to deposit.',
      'The maximum amount you can deposit is shown below'
    ],
    approveRequired: true,
    modal: {
      title: 'Supply',
      balance: 'Wallet balance',
      processing: 'Supplying',
      success: 'You supplied',
      totalSteps: 3
    }
  },
  {
    key: finOperationsEnum.borrow,
    title: 'Borrow',
    backLink: '/',
    contentAmountEntryForm: [
      'How much would you like to borrow?',
      'Please enter an amount you would like to borrow.',
      'The maximum amount you can borrow is shown below'
    ],
    approveRequired: false,
    modal: {
      title: 'Borrow',
      balance: 'Available to borrow',
      processing: 'Borrowing',
      success: 'You borrowed',
      totalSteps: 2
    }
  },
  {
    key: finOperationsEnum.repay,
    title: 'Repay',
    backLink: '/',
    contentAmountEntryForm: ['Repay', 'How much would you want to repay?'],
    approveRequired: true,
    modal: {
      title: 'Repay',
      balance: 'Available to repay',
      processing: 'Repaying',
      success: 'You repaid',
      totalSteps: 3
    }
  },
  {
    key: finOperationsEnum.withdraw,
    title: 'Withdraw',
    backLink: '/',
    contentAmountEntryForm: ['Withdraw', 'How much would you want to withdraw?'],
    approveRequired: false,
    modal: {
      title: 'Withdraw',
      balance: 'Available to withdraw',
      processing: 'Withdrawing',
      success: 'You withdrew',
      totalSteps: 2
    }
  }
]

export const gasConfig = {
  gasPrice: '0.02',
  maxFeePerGas: '0.05',
  maxPriorityFeePerGas: '0.01'
}
