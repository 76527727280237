import React, { Fragment } from 'react'
// import { ErrorBoundary } from '@sentry/nextjs'
import { NextPage } from 'next'
import Script from 'next/script'
import type { AppProps } from 'next/app'
import Head from 'next/head'

import { useStore } from '@/stores'

import Providers from '@/Providers'

import '@rainbow-me/rainbowkit/styles.css'

import '../styles/globals.css'

function MyApp(props: AppProps) {
  const { pageProps } = props
  const store = useStore(pageProps.initialState)
  const title = 'Dorian | Yield-first BTCFi money market on Core'

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=5, minimum-scale=1, viewport-fit=cover"
        />
        <title>{title}</title>
      </Head>
      <Providers store={store}>
        <App {...props} />
      </Providers>
      <Script
        strategy="afterInteractive"
        id="google-tag"
        dangerouslySetInnerHTML={{
          __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${process.env.NEXT_PUBLIC_GTAG}');
          `
        }}
      />
    </>
  )
}

type NextPageWithLayout = NextPage & {
  Layout?: React.FC
}

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout
}

// const ProductionErrorBoundary = process.env.NODE_ENV === 'production' ? ErrorBoundary : Fragment

const App = ({ Component, pageProps }: AppPropsWithLayout) => {
  // Use the layout defined at the page level, if available
  const Layout = Component.Layout || Fragment

  return (
    // <ProductionErrorBoundary>
    <Layout>
      <Component {...pageProps} />
    </Layout>
    // </ProductionErrorBoundary>
  )
}

// MyApp.getInitialProps = async (context) => {
//   const { req } = context
//   if (req) {
//     return { host: req.headers.host }
//   }

//   return {}
// }

export default MyApp
