export const fetchAccountPositionsData = async (accountAddress: string) => {
  const url = process.env.SUBGRAPH_URL || process.env.NEXT_PUBLIC_SUBGRAPH_URL || ''
  const id = accountAddress.toLowerCase()

  try {
    const response = await fetch(url, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        query: `query {
          account(id: "${id}") {
            positions {
              balance
              side
              market {
                name
                id
                inputToken {
                  id
                  decimals
                }
                outputTokenPriceUSD
                inputTokenPriceUSD
                dailySnapshots {
                  inputTokenPriceUSD
                  timestamp
                  blockNumber
                }
              }
              snapshots(orderBy: timestamp) {
                balance
                blockNumber
                timestamp
              }
              blockNumberClosed
              blockNumberOpened
            }
          }
        }`
      })
    })

    if (!response.ok) {
      throw new Error(`ERROR HTTP fetch Subgraph account positions: ${response.status}`)
    }

    const data = await response.json()

    return data
  } catch (error) {
    console.error('ERROR fetch Subgraph account positions:', error)
  }
}
