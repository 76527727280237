// add icons (96х96, .png) to '@/public/image' when adding new asset
import DAI from '@/public/image/DAI.png'
import USDC from '@/public/image/USDC.png'
import USDT from '@/public/image/USDT.png'
import WETH from '@/public/image/WETH.png'
import mETH from '@/public/image/METH.png'
import WBTC from '@/public/image/WBTC.png'
import LEND from '@/public/image/LENDLE.png'
import WMNT from '@/public/image/MNT.png'
import WKAVA from '@/public/image/KAVA.png'
import DEFAULT from '@/public/image/help.png'
import SLUSH from '@/public/image/SLUSH.png'
import stCORE from '@/public/image/stCORE.png'
import coreBTC from '@/public/image/coreBTC.png'
import solvBTCb from '@/public/image/solvBTC-b.png'
import solvBTCm from '@/public/image/solvBTC-m.png'
import aBTC from '@/public/image/aBTC.png'
import BTCB from '@/public/image/BTCb.png'
import WCORE from '@/public/image/WCORE.png'

export const getIconLink = (symbol) => {
  const iconLink = `/image/${symbol}.png`
  return iconLink
}

export const AssetsPriceList = Object.freeze({
  USDT: 'tether',
  USDC: 'usd-coin',
  DAI: 'dai',
  WETH: 'ethereum',
  WBTC: 'bitcoin',
  WKAVA: 'kava',
  LEND: 'lendle',
  WMNT: 'mantle',
  MMNT: 'mETH',
  WTODO1: 'todo1'
})

export const AssetsIcons = Object.freeze({
  USDT: USDT,
  USDC: USDC,
  DAI: DAI,
  WETH: WETH,
  WBTC: WBTC,
  WMNT: WMNT,
  MNT: WMNT,
  WKAVA: WKAVA,
  LEND: LEND,
  mETH: mETH,
  SLUSH: SLUSH,
  WCORE: WCORE,
  STCORE: stCORE,
  COREBTC: coreBTC,
  ABTC: aBTC,
  BTCB: BTCB,
  SOLVBTCB: solvBTCb,
  SOLVBTCM: solvBTCm,
  DEFAULT: DEFAULT
})

export const AssetsStableList: string[] = ['USDC', 'USDT', 'DAI']

export const AssetsColor: { name: string; color: string; colorSec?: string }[] = [
  {
    name: 'borrowing power available',
    color: 'transparent'
  },
  {
    name: 'USDC',
    color: '#2775ca'
  },
  {
    name: 'USDT',
    color: '#53ae94'
  },
  {
    name: 'WETH',
    color: '#3e3e3e'
  },
  {
    name: 'DAI',
    color: '#f5ac37'
  },
  {
    name: 'WBTC',
    colorSec: '#f09242',
    color: '#ffffff'
  },
  {
    name: 'WMNT',
    color: '#C1FF3C'
  },
  {
    name: 'WKAVA',
    color: '#FF564F'
  },
  {
    name: 'LEND',
    color: '#FF6700'
  },
  {
    name: 'mETH',
    color: '#7F0010'
  },
  {
    name: 'WCORE',
    color: '#FF9211'
  },
  {
    name: 'STCORE',
    color: '#31a7ea'
  },
  {
    name: 'COREBTC',
    color: '#f94229'
  },
  {
    name: 'ABTC',
    color: '#fbd4af'
  },
  {
    name: 'BTCB',
    color: '#f79319'
  },
  {
    name: 'SOLVBTCB',
    color: '#3f4044'
  },
  {
    name: 'SOLVBTCM',
    color: '#4f32e8'
  }
]

const lendTokenAddress = '0x25356aeca4210eF7553140edb9b8026089E49396'
export const DefaultLendPrice =
  typeof window !== 'undefined' ? localStorage.getItem(`price_${lendTokenAddress.toLowerCase()}`) ?? '0.00' : '0.00'

// TODO: add to data from contracts
export const incentiveDataEnum = {
  // annualEmission: 5333333.333
  annualEmission: 8056202.6244384,
  secondsPerYear: 31536000
}

export const projAllocation = '28000000000000000000000000'

export type AssetDataType = {
  id: string
  uOptimal: string
  slope1: string
  slope2: string
  tokenAddress: string
  lvTokenAddress: string
  decimals: number
  name: string
  lvName: string
  icon: any
  isActive?: boolean
}
export type AssetsDataType = {
  [key: number]: {
    [key: string]: AssetDataType
  }
}

export const assetsData: AssetsDataType = {
  1115: {
    USDC: {
      id: '0x086a532583cdf6d9666c978fa153b25816488cbb',
      uOptimal: '0x295BE96E640669720000000',
      slope1: '0x295be96e64066972000000',
      slope2: '0x295be96e640669720000000',
      tokenAddress: '0x086a532583cdf6d9666c978fa153b25816488cbb',
      decimals: 6,
      lvTokenAddress: '0xe5be769f555049f7c93f92cfb570fda8babe6a06',
      name: 'USDC',
      lvName: 'lvUSDC',
      icon: USDC,
      isActive: true
    },
    USDT: {
      id: '0x9a6d17e06db549ce12f4b351e132821f6689947a',
      uOptimal: '0x295BE96E640669720000000',
      slope1: '0x295be96e64066972000000',
      slope2: '0x295be96e640669720000000',
      tokenAddress: '0x9a6d17e06db549ce12f4b351e132821f6689947a',
      decimals: 6,
      lvTokenAddress: '0xbdd6ee1f3e68a0de53931f56a0cf95a9669be99f',
      name: 'USDT',
      lvName: 'lvUSDT',
      icon: USDT,
      isActive: true
    },
    WBTC: {
      id: '0xdc1f593f30f533b460f092cc2acfbca0715a4040',
      uOptimal: '0x14ADF4B7320334B90000000',
      slope1: '0x422ca8b0a00a4250000000',
      slope2: '0x033b2e3c9fd0803ce8000000',
      tokenAddress: '0xdc1f593f30f533b460f092cc2acfbca0715a4040',
      decimals: 8,
      lvTokenAddress: '0x4bbd3d2409563bb3261ca38689b2c9a31dac6cd5',
      name: 'WBTC',
      lvName: 'lvWBTC',
      icon: WBTC,
      isActive: true
    },
    WETH: {
      id: '0xb54a659e961febb5b489f9afa6e0d7e5e51552ed',
      uOptimal: '0x295BE96E640669720000000',
      slope1: '0x27B46536C66C8E30000000',
      slope2: '0x033b2e3c9fd0803ce8000000',
      tokenAddress: '0xb54a659e961febb5b489f9afa6e0d7e5e51552ed',
      decimals: 18,
      lvTokenAddress: '0xb41a9a6b1c07f5a09797dc3901a88d14b5805f15',
      name: 'WETH',
      lvName: 'lvWETH',
      icon: WETH,
      isActive: true
    },
    WCORE: {
      id: '0x91d715f6158fc108a39b334ed2c8d5a264bf4b5c',
      uOptimal: '0x295BE96E640669720000000',
      slope1: '0x27B46536C66C8E30000000',
      slope2: '0x033b2e3c9fd0803ce8000000',
      tokenAddress: '0x91d715f6158fc108a39b334ed2c8d5a264bf4b5c',
      decimals: 18,
      lvTokenAddress: '0xd5bc04e1b39bf8885855afc61117c7160bdd54a0',
      name: 'WCORE',
      lvName: 'lvWCORE',
      icon: WCORE,
      isActive: true
    }
  },
  1116: {
    USDC: {
      id: '0xa4151b2b3e269645181dccf2d426ce75fcbdeca9',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x2116545850052128000000',
      slope2: '0x295be96e640669720000000',
      tokenAddress: '0xa4151b2b3e269645181dccf2d426ce75fcbdeca9',
      decimals: 6,
      lvTokenAddress: '0xf8be78cc584ea9e794ee6850837bdfaa7c7dccce',
      name: 'USDC',
      lvName: 'lvUSDC',
      icon: USDC,
      isActive: true
    },
    USDT: {
      id: '0x900101d06a7426441ae63e9ab3b9b0f63be145f1',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x2116545850052128000000',
      slope2: '0x295be96e640669720000000',
      tokenAddress: '0x900101d06a7426441ae63e9ab3b9b0f63be145f1',
      decimals: 6,
      lvTokenAddress: '0x5f50d5c5683b13afa48ffa00143921581bd3e1e0',
      name: 'USDT',
      lvName: 'lvUSDT',
      icon: USDT,
      isActive: true
    },
    WBTC: {
      id: '0x5832f53d147b3d6cd4578b9cbd62425c7ea9d0bd',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x39E7139A8C08FA06000000',
      slope2: '0x9B18AB5DF7180B6B8000000',
      tokenAddress: '0x5832f53d147b3d6cd4578b9cbd62425c7ea9d0bd',
      decimals: 8,
      lvTokenAddress: '0x25a511ed5e95b08af2cb2f6209bb4282ab5d166d',
      name: 'WBTC',
      lvName: 'lvWBTC',
      icon: WBTC,
      isActive: true
    },
    WETH: {
      id: '0xeab3ac417c4d6df6b143346a46fee1b847b50296',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x27B46536C66C8E30000000',
      slope2: '0x033b2e3c9fd0803ce8000000',
      tokenAddress: '0xeab3ac417c4d6df6b143346a46fee1b847b50296',
      decimals: 18,
      lvTokenAddress: '0x252aa215b2c5bb5b7772bc72685f6a174cfb2392',
      name: 'WETH',
      lvName: 'lvWETH',
      icon: WETH,
      isActive: true
    },
    WCORE: {
      id: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x39E7139A8C08FA06000000',
      slope2: '0x9B18AB5DF7180B6B8000000',
      tokenAddress: '0x40375c92d9faf44d2f9db9bd9ba41a3317a2404f',
      decimals: 18,
      lvTokenAddress: '0x3dfc71510855357f64da7d3e7b586380d35d605f',
      name: 'WCORE',
      lvName: 'lvWCORE',
      icon: WCORE,
      isActive: true
    },
    STCORE: {
      id: '0xb3a8f0f0da9ffc65318aa39e55079796093029ad',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x39E7139A8C08FA06000000',
      slope2: '0x9B18AB5DF7180B6B8000000',
      tokenAddress: '0xb3a8f0f0da9ffc65318aa39e55079796093029ad',
      decimals: 18,
      lvTokenAddress: '0xd5bc04e1b39bf8885855afc61117c7160bdd54a0',
      name: 'stCORE',
      lvName: 'lvstCORE',
      icon: stCORE,
      isActive: false
    },
    COREBTC: {
      id: '0x8034ab88c3512246bf7894f57c834dddbd1de01f',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x39E7139A8C08FA06000000',
      slope2: '0x9B18AB5DF7180B6B8000000',
      tokenAddress: '0x8034ab88c3512246bf7894f57c834dddbd1de01f',
      decimals: 8,
      lvTokenAddress: '0xa4ce0fd3c4dab462a0f1e0924f2aa2f5dfaf6728',
      name: 'COREBTC',
      lvName: 'lvCOREWBTC',
      icon: coreBTC,
      isActive: false
    },
    ABTC: {
      id: '0x70727228db8c7491bf0ad42c180dbf8d95b257e2',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x39E7139A8C08FA06000000',
      slope2: '0x9B18AB5DF7180B6B8000000',
      tokenAddress: '0x70727228db8c7491bf0ad42c180dbf8d95b257e2',
      decimals: 18,
      lvTokenAddress: '0xbb163fb061da00374079d764563610b39b032d4e',
      name: 'ABTC',
      lvName: 'lvABTC',
      icon: aBTC,
      isActive: false
    },
    BTCB: {
      id: '0x7a6888c85edba8e38f6c7e0485212da602761c08',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x39E7139A8C08FA06000000',
      slope2: '0x9B18AB5DF7180B6B8000000',
      tokenAddress: '0x7a6888c85edba8e38f6c7e0485212da602761c08',
      decimals: 18,
      lvTokenAddress: '0x4dbdca2b3aad2f960d198b6bd5372c00146551a3',
      name: 'BTCB',
      lvName: 'lvBTCB',
      icon: BTCB,
      isActive: false
    },
    SOLVBTCB: {
      id: '0x5b1fb849f1f76217246b8aaac053b5c7b15b7dc3',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x39E7139A8C08FA06000000',
      slope2: '0x9B18AB5DF7180B6B8000000',
      tokenAddress: '0x5b1fb849f1f76217246b8aaac053b5c7b15b7dc3',
      decimals: 18,
      lvTokenAddress: '0xe2457d774c888ee6f82cfa1a180bbd3b917a881a',
      name: 'SOLVBTCB',
      lvName: 'lvSOLVBTCB',
      icon: solvBTCb,
      isActive: false
    },
    SOLVBTCM: {
      id: '0xe04d21d999faedf1e72ade6629e20a11a1ed14fa',
      uOptimal: '0x1743B34E18439B502000000',
      slope1: '0x39E7139A8C08FA06000000',
      slope2: '0x9B18AB5DF7180B6B8000000',
      tokenAddress: '0xe04d21d999faedf1e72ade6629e20a11a1ed14fa',
      decimals: 18,
      lvTokenAddress: '0xe3e0b2db5d5f0cdf67d6141da015f515569912d9',
      name: 'SOLVBTCM',
      lvName: 'lvSOLVBTCM',
      icon: solvBTCm,
      isActive: false
    }
  }
}
