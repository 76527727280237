var _sentryCollisionFreeGlobalObject = typeof window === "undefined" ? global : window;
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"459fe5a30aead9e566ab4ce416f08218515ca34b"};
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// // This file configures the initialization of Sentry on the client.
// // The config you add here will be used whenever a users loads a page in their browser.
// // https://docs.sentry.io/platforms/javascript/guides/nextjs/

// import * as Sentry from '@sentry/nextjs'

// const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN

// Sentry.init({
//   dsn: SENTRY_DSN || 'https://96cdf2a57e3703811f55a7c5b99ee23b@sentry.pozychaty.xyz/1',
//   // Adjust this value in production, or use tracesSampler for greater control
//   tracesSampleRate: 1.0,
//   // ...
//   // Note: if you want to override the automatic release value, do not set a
//   // `release` value here - use the environment variable `SENTRY_RELEASE`, so
//   // that it will also get attached to your source maps

//   // Setting this option to true will print useful information to the console while you're setting up Sentry.
//   debug: false,

//   replaysOnErrorSampleRate: 1.0,

//   // This sets the sample rate to be 10%. You may want this to be 100% while
//   // in development and sample at a lower rate in production
//   replaysSessionSampleRate: 0.1

//   // You can remove this option if you're not planning to use the Sentry Session Replay feature:
//   // integrations: [
//   //   new Sentry.Replay({
//   //     // Additional Replay configuration goes in here, for example:
//   //     maskAllText: true,
//   //     blockAllMedia: true
//   //   })
//   // ]
// })
