import { useContext, createContext } from 'react'

import WalletAccountStore from './walletAccountStore'
import ModalStore from './modalStore'
import GlobalStore from './globalStore'
import GraphStore from './graphStore'
import YieldsStore from './yieldsStore'

class RootStore {
  public walletAccountStore: WalletAccountStore

  public modalStore: ModalStore

  public globalStore: GlobalStore

  public graphStore: GraphStore

  public yieldsStore: YieldsStore

  constructor() {
    this.walletAccountStore = new WalletAccountStore(this)
    this.modalStore = new ModalStore(this)
    this.globalStore = new GlobalStore(this)
    this.graphStore = new GraphStore(this)
    this.yieldsStore = new YieldsStore(this)
  }

  hydrate = (data: this) => {
    if (!data) return
    this.setHydrateData(data)
  }

  private setHydrateData(data: this) {
    this.modalStore = data.modalStore
    this.globalStore = data.globalStore
    this.walletAccountStore = data.walletAccountStore
    this.yieldsStore = data.yieldsStore
  }
}

// init a client store that we will send to client (one store for client)
let clientStore: RootStore

const initStore = (initData: RootStore) => {
  // check if we already declare store (client Store), otherwise create one
  const store = clientStore ?? new RootStore()
  // hydrate to store if receive initial data
  if (initData) store.hydrate(initData)

  // Create a store on every server request
  if (typeof window === 'undefined') return store
  // Otherwise it's client, remember this store and return
  if (!clientStore) clientStore = store
  return store
}

// Hook for using store
export function useStore(initData: RootStore) {
  return initStore(initData)
}

export const RootStoreContext = createContext<RootStore>({} as RootStore)
export const useAppState = () => useContext(RootStoreContext)
export default RootStore
