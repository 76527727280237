import { AvatarComponent } from '@rainbow-me/rainbowkit'
import Avatar from '@/public/svg/Avatar.svg'

const AvatarCustom: AvatarComponent = ({ ensImage, size = 40 }) =>
  ensImage ? (
    <img src={ensImage} width={size} height={size} alt="avatar" style={{ borderRadius: 999 }} />
  ) : (
    <div>
      <Avatar />
    </div>
  )

export default AvatarCustom
