let chainID = 0

export const defaultChainId = process.env.NEXT_PUBLIC_CHAIN_ID ? Number(process.env.NEXT_PUBLIC_CHAIN_ID) : 1115
export const defaultChainRPC = process.env.NEXT_PUBLIC_CHAIN_RPC || 'https://rpc.test.btcs.network'
export const defaultChainWS = process.env.NEXT_PUBLIC_CHAIN_WS || 'wss://rpc.test.btcs.network'
export const defaultWalletAddress =
  process.env.NEXT_PUBLIC_WALLET_ADDRESS || '0x62E80E033C303eFF885360843b497Fa3E5003D4d'

export function ChainConfig(selectedChainId) {
  // const selectedChainId = Object.entries(CHAIN_ID_NETWORK).find(([key, value]) => value === selectedNetwork)[0]
  const chainId = parseInt(selectedChainId, 10)
  chainID = chainId
  const NODE_RPC = process.env[`${chainId}_PUBLIC_NODE`]
  const NODE_NAME = process.env[`${chainId}_PUBLIC_NODE_NAME`]
  return {
    chainId,
    chainName: NODE_NAME,
    rpcUrls: [NODE_RPC]
  }
}

export const chainId = chainID

export const CHAIN_ID_NETWORK = {
  1115: 'Core Blockchain Testnet',
  1116: 'Core Blockchain'
}

export const explorerURL = {
  1115: 'https://scan.test.btcs.network',
  1116: 'https://scan.coredao.org'
}

export const decimalsETH = {
  1115: 18,
  1116: 18
}
