import airdrop1 from '@/airdrops/airdrop1.json'
import airdrop2 from '@/airdrops/airdrop2.json'
import airdrop3 from '@/airdrops/airdrop3.json'
import airdrop4 from '@/airdrops/airdrop4.json'
import { LpUtils } from '@/stores/utils/lpUtils'

import { Contract } from 'ethers'
import BalanceTree from './balance-tree'

const airdrops = [airdrop1, airdrop2, airdrop3, airdrop4]
export const claim = async (account, provider, airdropIndex = 0): Promise<any> => {
  let index = 0
  let amount = BigInt(0)
  const address = account.toLowerCase()
  const tree = new BalanceTree(
    Object.entries(airdrops[airdropIndex]).map(([acc, am], i) => {
      const _amount = BigInt(am)

      if (address === acc) {
        index = i
        amount = _amount
      }

      return {
        account: acc,
        amount: _amount
      }
    })
  )

  if (amount === BigInt(0)) {
    throw new Error('No airdrop for this account')
  }

  const proof = tree.getProof(index, account, amount)
  const lpUtils = new LpUtils(provider)
  await lpUtils.setLpConfig()
  const { merkleDistributionContract: contract } = await lpUtils.getLpConfig()

  const runner = await provider.getSigner()
  const _contract: Contract = contract.connect(runner) as Contract
  return _contract.claim(airdropIndex, index, amount, account, proof)
}
