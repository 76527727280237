import React from 'react'
import { RainbowKitProvider } from '@rainbow-me/rainbowkit'
import { WagmiConfig } from 'wagmi'
import { ApolloProvider } from '@apollo/client'

import { LanguageProvider } from '@/contexts/Localization'
import RootStore, { RootStoreContext } from '@/stores'

import AvatarCustom from '@/components/AvatarCustom'

import connectConfig from '@/utils/wagmi'
import apolloClient from '@/utils/apollo'

const Providers: React.FC<{ store: RootStore }> = ({ children, store }) => (
  <WagmiConfig config={connectConfig.wagmiConfig as any}>
    <RainbowKitProvider avatar={AvatarCustom} chains={connectConfig.chains}>
      <RootStoreContext.Provider value={store}>
        <ApolloProvider client={apolloClient}>
          <LanguageProvider>{children}</LanguageProvider>
        </ApolloProvider>
      </RootStoreContext.Provider>
    </RainbowKitProvider>
  </WagmiConfig>
)

export default Providers
